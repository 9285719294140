
import CompanyMap from "@/components/CompanyMap.vue";
export default {
  data() {
    return {
      companyData: {
        osaka: {
          id:1,
          title: "大阪本社",
          postID: "550-0014",
          adress: "大阪市西区北堀江1-6-2 サンワールドビル5F",
          mapURL:
            "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d13124.836564693267!2d135.47819732010663!3d34.67467070954666!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6000e70533d33f2f%3A0xcdaf27ab3f52214d!2z5qCq5byP5Lya56S-IOadkeeUsOOCveODleODiOOCpuOCp-OCouOCteODvOODk-OCuQ!5e0!3m2!1sja!2sjp!4v1710225962752!5m2!1sja!2sjp",
        },
        osaka2: {
          id:3,
          title: "大阪第二オフィス",
          postID: "550-0014",
          adress: "大阪府大阪市西区北堀江１丁目５−２ 四ツ橋新興産ビル706",
          mapURL:
            "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3281.238762702599!2d135.496481!3d34.673922999999995!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6000e7054edf0b41%3A0x31c36554ec2aca07!2z44CSNTUwLTAwMTQg5aSn6Ziq5bqc5aSn6Ziq5biC6KW_5Yy65YyX5aCA5rGf77yR5LiB55uu77yV4oiS77ySIOWbm-ODhOapi-aWsOiIiOeUo-ODk-ODqyA3MDY!5e0!3m2!1sja!2sjp!4v1712899559859!5m2!1sja!2sjp",
        },
        tokyo: {
          id:2,
          title: "東京支社",
          postID: "107-0061",
          adress: "東京都港区北青山2丁目7−13 プラセオ青山ビル 3F",
          mapURL:
            "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3241.243966441283!2d139.7152248752467!3d35.67099437259058!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x60188c9bf5eebd71%3A0x8eb3dedf338a68b9!2z44CSMTA3LTAwNjEg5p2x5Lqs6YO95riv5Yy65YyX6Z2S5bGx77yS5LiB55uu77yX4oiS77yR77yTIOODl-ODqeOCu-OCqumdkuWxseODk-ODqyAz6ZqO!5e0!3m2!1sja!2sjp!4v1710226330087!5m2!1sja!2sjp",
        },
      },
    };
  },
  components: {
    CompanyMap,
  },
  metaInfo() {
    return {
      title: '会社概要 | 株式会社村田ソフトウェアサービス',
      meta: [
        {
          name: 'description',
          content: '株式会社村田ソフトウェアサービスの会社概要ページです。'
        },
      ],
    };
  },
};


import Vue from "vue";

export default Vue.extend({
  data() {
    return {
      isButtonDisabled: false,
      showStickyContainer: true,
    };
  },
  mounted() {
    window.addEventListener("scroll", this.handleScroll);
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.handleScroll);
  },
  methods: {
    handleScroll() {
      const howToApply = document.getElementById("HowtoApply");
      const stickyContainer = document.getElementById("sticky-container");
      if (!howToApply || !stickyContainer) return;

      const howToApplyRect = howToApply.getBoundingClientRect();
      const stickyRect = stickyContainer.getBoundingClientRect();

      if (howToApplyRect.top < stickyRect.bottom) {
        this.showStickyContainer = false; // 非表示にする
        this.isButtonDisabled = true; // ボタンを無効化
      } else {
        this.showStickyContainer = true; // 表示する
        this.isButtonDisabled = false; // ボタンを有効化
      }
    },
  },
  components: {},
  metaInfo() {
    return {
      title: "採用情報 | 株式会社村田ソフトウェアサービス",
      meta: [
        {
          name: "description",
          content: "株式会社村田ソフトウェアサービスの採用ページです。",
        },
      ],
    };
  },
});


import Vue from "vue";
import { sendMailData } from "../api";
export default Vue.extend({
  data() {
    return {
      navs: [["ホーム", "home"]],
      formdata: {
        content: "",
        last_name: "",
        first_name: "",
        last_name_kana: "",
        first_name_kana: "",
        phone: "",
        email: "",
        kind: "",
      },
      alert_kana: "",
      alert_mail: "",
      alert_phone: "",
      alert_text: "",
      isSend: false,
    };
  },
  components: {},
  mounted: function () {
    this.isSend = false;
  },
  methods: {
    // ひらがなをカタカナに変換するメソッド
    convertToKatakana(str: string): string {
      // ひらがなをカタカナに変換する処理
      return str.replace(/[ぁ-ん]/g, function(s: string) {
        return String.fromCharCode(s.charCodeAt(0) + 0x60);
      });
    },
    validation_kanacheck() {
      const isKana = /^[ァ-ヶーぁ-ゞ]+$/;
      let checkLast = this.formdata.last_name_kana.match(isKana);
      let checkFirst = this.formdata.first_name_kana.match(isKana);
      if (
        checkLast != null &&
        checkFirst != null &&
        this.formdata.last_name_kana != "" &&
        this.formdata.first_name_kana != ""
      ) {
        // フリガナが入力されており、カタカナである場合
        this.alert_kana = "";
        return true;
      } else if (
        this.formdata.last_name_kana == "" &&
        this.formdata.first_name_kana == ""
      ) {
        // フリガナが未入力の場合
        this.alert_kana = "";
        this.alert_text = "フリガナが入力されていません。";
      } else {
        // フリガナが入力されているが、カタカナでない場合
        this.alert_kana = "カナで入力されていません。";
        this.alert_text = this.alert_kana;
        
        // 入力されたひらがなをカタカナに変換する
        this.formdata.last_name_kana = this.convertToKatakana(this.formdata.last_name_kana);
        this.formdata.first_name_kana = this.convertToKatakana(this.formdata.first_name_kana);
      }
    },
    validation_mail() {
      let checkMail = this.formdata.email.match(/.+@.+\..+/);
      if (checkMail != null) {
        this.alert_mail = "";
        return true;
      } else if (this.formdata.email == "") {
        this.alert_mail = "";
        this.alert_text = "メールアドレスが入力されていません。";
      } else {
        this.alert_mail = "メールアドレスの形式が正しくありません。";
        this.alert_text = this.alert_mail
      }
    },
    validation_phone() {
      let checkPhone = this.formdata.phone.match(/^[0-9-]+$/);
      if (checkPhone != null) {
        this.alert_phone = "";
        return true;
      } else if (this.formdata.phone == "") {
        this.alert_phone = "";
        this.alert_text = "電話番号が入力されていません。";
      } else {
        this.alert_phone = "電話番号の形式が正しくありません。";
        this.alert_text = this.alert_phone
      }
    },
    async sendMail() {
      this.alert_text = "";
      if (
        this.validation_kanacheck() == true &&
        this.validation_mail() == true &&
        this.validation_phone() == true
      ) {
        if (this.formdata.content == "") {
          this.alert_text = "本文が入力されていません。";
        }
        if (this.formdata.last_name == "" && this.formdata.first_name == "") {
          this.alert_text = "名前が入力されていません。";
        }
        if (this.formdata.kind == "") {
          this.alert_text = "お問い合わせ種目が入力されていません。";
        }
        if(this.alert_text != "") {
          alert(this.alert_text);
          return;
        }
        //チェックOK
        this.alert_text = "";
        let json = JSON.stringify(this.formdata);
        const res = await sendMailData(json);
        this.isSend = res;
        if (res == false) {
          this.alert_text = "送信エラーが発生しました。";
        }
      }
      if(this.alert_text != "") {
        alert(this.alert_text);
      }
    },
  },
  metaInfo() {
    return {
      title: 'お問い合わせ | 株式会社村田ソフトウェアサービス',
      meta: [
        {
          name: 'description',
          content: '株式会社村田ソフトウェアサービスのお問い合わせページです。'
        },
      ],
    };
  },
});
